.top-bar {
  color: white;
  a {
    color: white;
  }
  .top-bar-left {
    @include breakpoint(small only) {
      width: auto;
    }
  }
  .top-bar-right {
    @include breakpoint(small only) {
      width: auto;
    }
  }
  i {
    font-size: 2em;
    line-height: 1em;
    margin-right: 0.1em;
  }

  .facebook a {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.off-canvas {
  a {
    color: white;
  }
}

.mobile-logo {
  width: 50%;
}

.content-wrapper {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

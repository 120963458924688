.hero {
    padding: 2em;
    background-color: #f7f7f7;
    margin-bottom: 2em;
}

.logo-wrapper {
    padding-right: 2em;
    margin-bottom: 2em;
    @include breakpoint(large up) {
        margin-bottom: 0;
    }
}

.location-info {
    text-align: center;
    > div {
        margin-bottom: 1em;
        color: darken($dark-gray, 20%);
    }
    @include breakpoint(small only) {
        h4 {
            font-size: 2em;
        }
    }
}

.image-background-wrapper {
    @include breakpoint(medium up) {
        position: fixed;
        top: 54px;
        bottom: 0;
        right: 0;
        left: 0;
        background: url("/images/baby-background.jpg") no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;

        .overlay {
            position: relative;
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, .3);
        }

        .content-wrapper {
            position: absolute;
            top: 0;
            //top: 50%;
            //left: 50%;
            //transform: translate(-50%, -50%);
            background-color: rgba(255, 255, 255, .8);
            width: 100%;
            padding: 2em 0 12em 0;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0.6+64,0+100 */
            background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.6) 64%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.6) 64%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.6) 64%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=0); /* IE6-9 */
        }
    }

    .doctor-names {
        position: absolute;
        bottom: 0;
        background-color: rgba(0, 0, 0, .6);
        color: white;
        width: 100%;
        padding: .75em 0;
        text-align: center;
        font-family: 'Roboto Condensed', sans-serif;
        @include breakpoint(large up) {
            font-size: 1.5em;
        }
        text-shadow: -1px 1px 2px rgba(0, 0, 0, .8);
        > a {
            color: white;
            display: inline-block;
            width: 24%;
            text-align: center;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
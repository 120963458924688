@import "settings";
@import "../node_modules/foundation-sites/scss/foundation";
@import "../node_modules/flatpickr/dist/flatpickr.airbnb.min.css";

@include foundation-everything; // todo selective import?

@import "./header";
@import "./home";
@import "./sidebar";

.doctor-photo {
  @include breakpoint(medium) {
    float: right;
    margin: 0 0 10px 10px;
    max-width: 50%;
  }
  @include breakpoint(large up) {
    max-width: 30%;
  }
}

.services-image {
  float: right;
  width: 50%;
  margin: 10px 0 10px 10px;
  @include breakpoint(medium only) {
    width: 40%;
  }
  @include breakpoint(large up) {
    width: 30%;
  }
}

.well-visit-schedule {
  .visit-wrapper {
    height: 100%;
    border: 1px solid $medium-gray;
    border-left: 0;
    padding: 0 1em 1em 1em;
  }

  > div {
    margin-bottom: 2em;
    @include breakpoint(small only) {
      border-left: 1px solid $medium-gray;
    }
    @include breakpoint(medium only) {
      border-left: 0;
      &:nth-child(2n + 1) {
        .visit-wrapper {
          border-left: 1px solid $medium-gray;
        }
      }
    }
    @include breakpoint(large up) {
      border-left: 0;
      &:nth-child(4n + 1) {
        .visit-wrapper {
          border-left: 1px solid $medium-gray;
        }
      }
    }
  }
  h5 {
    background-color: $light-gray;
    margin-left: -15px;
    margin-right: -15px;
    text-align: center;
    padding: 0.5em 15px 0.5em 15px;
  }
  ul {
    list-style-type: none;
    margin: 0;
  }
}

.resources {
  ul {
    list-style-type: none;
    margin: 0;
    li {
      margin-bottom: 1em;
    }
  }
}

.milestones {
  ul {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    @include breakpoint(medium up) {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
    }
    list-style-type: none;
    margin: 0;
    margin-top: 2em;
    li {
      a {
        display: inline-block;
        margin-bottom: 1em;
      }
    }
  }
}

.hospitals {
  h3 {
    margin-top: 1em;
  }
}

.map-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.new-patient-form {
  input[readonly] {
    background-color: white;
  }
}

small.error {
  color: darkred;
  margin-bottom: 15px;
  display: block;
  margin-top: -10px;
}

.family-pic {
  margin: 2em 0;
  display: block;
  width: 40%;
}

.all-doctors {
  margin: 2rem 0;
  img {
    display: block;
  }
  > div {
    margin-top: 0.5rem;
    text-align: center;
    font-size: 1.3rem;
  }
}

.all-staff {
  display: grid;
  grid-template-columns: 1fr;
  @include breakpoint(medium) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include breakpoint(large up) {
    grid-template-columns: repeat(3, 1fr);
  }
  grid-column-gap: 3rem;
  grid-row-gap: 2rem;
  text-align: center;

  .staff-name {
    margin-top: 0.5rem;
    font-size: 1.3rem;
  }

  .staff-position {
    color: rgba(0, 0, 0, 0.6);
  }

  > div {
    img {
      display: block;
      width: 70%;
      @include breakpoint(medium up) {
        width: 100%;
        height: 330px;
      }
      object-fit: cover;
      margin: 0 auto;
    }
  }
}

nav.sidebar {
    padding-top: 2em;
    .logo {
        margin-bottom: 1.5em;
    }
    h5 {
        text-align: center;
    }
    .callout {
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        > div {
            font-size: 0.9em;
            margin-bottom: 1em;
            color: $dark-gray;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}